@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  margin: 0;
  width: 100%;
  height: 100vh;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  background-color: black;
}



*{
margin: 0;
padding: 0;
box-sizing: border-box;
-webkit-touch-callout: none; /* iOS Safari */
-webkit-user-select: none; /* Safari */
-khtml-user-select: none; /* Konqueror HTML */
-moz-user-select: none; /* Old versions of Firefox */
-ms-user-select: none; /* Internet Explorer/Edge */
user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@font-face {
  font-family: "MorrisRoman_Black";
  src: local("MorrisRoman_Black"),
   url("./fonts/MorrisRoman_Black.ttf") format("truetype");
  font-weight: bold;
  }
  @font-face {
    font-family: "Aniron_bold";
    src: local("Aniron_bold"),
     url("./fonts/Aniron_bold.ttf") format("truetype");
    font-weight: bold;
    }

  

h1{
 font-family: "MorrisRoman_Black";
}
h2{
  font-family: "MorrisRoman_Black";
}
h3{
  font-family: "MorrisRoman_Black";
}
h4{
  font-family: "MorrisRoman_Black";
}
h5{
  font-family: "MorrisRoman_Black";
}
h6{
  font-family: "MorrisRoman_Black";
}
p{
  font-family: 'MorrisRoman_Black';
}
span{
  font-family: 'MorrisRoman_Black';
}
/*---PreLoader---*/
#preloader {
  position: fixed;
  z-index: 100;
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
  background-color: black;
}






@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0.5;
    transform: scale(1);
  }
}
  






