
/*--glass--*/
.glass{
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 20px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border:1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  @media only screen and (max-width: 600px) {
    margin-left: 10px;
    margin-right: 10px;
  }
}

/*--fireflies*/
.containerfirefly {
  margin-top: 100%;
  overflow: hidden;
	position: absolute;
  width: 100%;
  top: 0;
  height:100%;
  object-fit: cover;
  z-index: 5;
	height: 100vh;


.firefly {
	position: absolute;
	width: 10px;
	height: 10px;
	background-color: yellow;
	border-radius: 50%;
	box-shadow: 0 0 10px yellow;
	z-index: 1;
	animation: firefly-rotate 6s linear infinite;
 
  @media only screen and (max-width: 768px) {
    width: 10px;
    height: 10px;
  }
}

.firefly:nth-of-type(1) {
	top: 50%;
	left: 10%;
	animation-delay: 0s;
}

.firefly:nth-of-type(2) {
	top: 20%;
	left: 20%;
	animation-delay: 1s;
}

.firefly:nth-of-type(3) {
	top: 10%;
	left: 50%;
	animation-delay: 2s;
}

.firefly:nth-of-type(4) {
	top: 20%;
	left: 80%;
	animation-delay: 3s;
}

.firefly:nth-of-type(5) {
	top: 50%;
	left: 90%;
	animation-delay: 4s;
}

.firefly:nth-of-type(6) {
	top: 80%;
	left: 80%;
	animation-delay: 5s;
}

.firefly:nth-of-type(7) {
	top: 90%;
	left: 50%;
	animation-delay: 6s;
}

@keyframes firefly-rotate {
	0% {
		transform: rotate(0deg) translateX(50px) rotate(0deg);
	}
	100% {
		transform: rotate(360deg) translateX(50px) rotate(-360deg);
	}
}
}



.welcomeheard{
 
    font-size: 100px;
    @media only screen and (max-width: 768px) {
      font-size: 40px;
   
    }
}

/*---- Home-Section 1---*/
.Homehero{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  min-height:100vh;
  z-index: 0;
  .Homebgglow{
    background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  z-index: 0;
  animation-name: opacity-transition;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  @keyframes opacity-transition {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  }
  

.Homebg{
  z-index: -1;
}
  .Homehero-text-center {
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    
  }
  
  
  
   p {
    font-size: 20px;
    color:rgba(77, 2, 2, 0.918);
    font-family: 'Alkatra', cursive;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 10%;
    padding-right: 10%;

    @media only screen and (max-width: 768px) {
      font-size: 15px;
      padding-top: 5%;
    padding-bottom: 5%;
      padding-left: 15%;
    padding-right: 15%;
    }
  }

}

/*---scrollimages--*/
.home6{
  overflow: hidden;
  .home6_1{
section {
  width: 100%;
}

article {
  display: flex;
  width: 200%;
  animation: bannermove 40s linear infinite;
}

article.paused {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

div {
  width: 100%;
}

ul {
  display: flex;
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}
img{
  padding-left: 20px;
}

@keyframes bannermove {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@media only screen and (max-width: 1280px) {
  article {
    width: 500%;
  }
}
@media only screen and (max-width: 1024px) {
  article {
    width: 600%;
  }
}
@media only screen and (max-width: 768px) {
  article {
    width: 850%;
  }
}
}

}

/*---TextScroll ---*/
.containerscroll {
  min-height: 80vh;
  overflow: hidden;


.contentscroll {
  position: relative;
  min-height: 80vh;
}

.scrolling-text {
  position: absolute;
  text-align: center;
  bottom: 0;
  width: 100%;
  font-size: 14px;
  animation: scroll 40s linear infinite;
}

@keyframes scroll {
  0% {
    top: 100%;
  }
  100% {
    top: -100%;
  }
}
}

/*--- Road Map ---*/
.FAQ{
  text-align: center;

 
.faqcontent{
  text-align: center;

}

  span{
    color: #ffffff;
    font-size: 25px;
    @media only screen and (max-width: 768px) {
      font-size: 15px;
    }
  }
  h2{
   
    color: #ffffff;
    padding: 0;
    padding-bottom: 30px;
    margin: 0;
    font-size: 100px;
    @media only screen and (max-width: 768px) {
      font-size: 50px;
      text-align: center;
      
    }
  }

  p{
    color: #ffffff;
  }
  
}













